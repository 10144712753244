/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@/components/common/skeleton';
import { space } from '@airwallex/global';

const Index: React.FC = () => (
  <div
    data-testid="mobileSkeleton"
    css={css`
      width: 100%;
      max-width: 480px;
      margin: ${space.xl} auto 0;
      padding-left: ${space.m};
      padding-right: ${space.m};
    `}
  >
    <Skeleton width="100%" height="23px" />
    <Skeleton
      width="100%"
      height="40px"
      css={css`
        margin: ${space.m} 0 ${space.xl};
      `}
    />
    <Skeleton width="100%" height="40px" />
    <Skeleton
      width="85%"
      height="40px"
      css={css`
        margin: ${space.s} 0 ${space.s};
      `}
    />
    <Skeleton width="100%" height="40px" />
    <Skeleton
      width="85%"
      height="40px"
      css={css`
        margin: ${space.s} 0 ${space.s};
      `}
    />
    <Skeleton width="100%" height="40px" />
  </div>
);

export default Index;
