/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@/components/common/skeleton';
import { space } from '@airwallex/global';

const Index: React.FC = () => (
  <div
    css={css`
      width: 100%;
      max-width: 440px;
    `}
    data-testid="rightSkeleton"
  >
    <Skeleton width="120px" height="23px" />
    <Skeleton
      width="120px"
      height="21px"
      css={css`
        margin: ${space.m} 0 ${space.m};
      `}
    />
    <Skeleton width="100%" height="40px" />
    <Skeleton
      width="100%"
      height="40px"
      css={css`
        margin: ${space.xl} 0 ${space.s};
      `}
    />
    <Skeleton width="100%" height="40px" />
    <Skeleton
      width="100%"
      height="40px"
      css={css`
        margin: ${space.s} 0 ${space.s};
      `}
    />
    <Skeleton width="100%" height="40px" />

    <Skeleton
      width="100%"
      height="40px"
      css={css`
        margin-top: ${space.s};
      `}
    />
  </div>
);

export default Index;
