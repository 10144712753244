/** @jsx jsx */
import { jsx } from '@emotion/react';
import { styled } from '@airwallex/theme';
import React, { HTMLAttributes } from 'react';
import { css } from '@emotion/core';
import { breakpoints } from '@airwallex/global';
import { white } from '@/components/common/palette';
import { useLargeScreen } from '@/hooks/useMediaQuery';

export const Container = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.white};
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    ${breakpoints.breakpoint.s} {
      padding: 0 ${theme.space.xs};
    }
  `
);

export const containerCss = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const desktopCss = css`
  background: white;
  padding: 0px;
  scrollbar-gutter: stable both-edges;
  &::before {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background: ${white};
    content: ' ';
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    width: 50%;
    box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
  }
`;

export const PayByLinkContainer: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  return (
    <div
      css={css`
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: row;
        padding: 120px 0;
        min-height: 100vh;
        ${breakpoints.breakpoint.l} {
          padding: 56px 0;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export const SplitScreenContainer: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  const isLargeScreen = useLargeScreen();
  return (
    <div css={isLargeScreen ? desktopCss : containerCss} {...rest}>
      {children}
    </div>
  );
};
