/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { SplitScreenContainer } from '@/components/layout';
import { useLargeScreen } from '@/hooks/useMediaQuery';
import MobileSkeleton from './mobileSkeleton';
import LeftSkeleton from './leftSkeleton';
import RightSkeleton from './rightSkeleton';

const Index: React.FC = () => {
  const isLargeScreen = useLargeScreen();
  return (
    <SplitScreenContainer>
      {!isLargeScreen ? (
        <MobileSkeleton />
      ) : (
        <div
          css={css`
            margin: 120px auto 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            max-width: 1400px;
            width: 100%;
          `}
        >
          <div
            css={css`
              flex: 1;
            `}
          />
          <div
            css={css`
              flex: 10;
              display: flex;
              flex-direction: row;
            `}
          >
            <div
              css={css`
                flex: 4;
              `}
            >
              <LeftSkeleton />
            </div>
            <div
              css={css`
                flex: 1;
              `}
            />
            <div
              css={css`
                flex: 1;
              `}
            />
            <div
              css={css`
                flex: 4;
              `}
            >
              <RightSkeleton />
            </div>
          </div>
          <div
            css={css`
              flex: 1;
            `}
          />
        </div>
      )}
    </SplitScreenContainer>
  );
};

export default Index;
