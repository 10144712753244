/** @jsx jsx */
import { jsx, css, Global } from '@emotion/react';
import React, { useEffect } from 'react';
import i18n from '@/utils/i18n';
import { LangKey as awxLangKey } from '@airwallex/global';
import { ThemeProvider } from '@airwallex/theme';
import { getLanguage } from '@/utils/language';
import { StoreProvider } from '@/providers/storeProvider';

import Route from './pages';
import { airTracker } from './utils/airTracker';

const App: React.FC = () => {
  const { language } = i18n;

  useEffect(() => {
    const langKey = getLanguage();
    airTracker.updateCommonData({ lang: langKey });
    i18n.changeLanguage(langKey);
  }, []);

  return (
    <React.Fragment>
      <StoreProvider>
        <ThemeProvider langKey={language as awxLangKey}>
          <Global
            styles={css`
              input {
                font-family: AxLLCircular, Helvetica, Arial, sans-serif;
              }
              @font-face {
                font-family: AxLLCircular;
                src: url(https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2);
                font-weight: 400;
                font-style: normal;
              }
              @font-face {
                font-family: AxLLCircular;
                src: url(https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXSub-BoldSubset.woff2);
                font-weight: 500;
                font-style: normal;
              }
            `}
          />
          <Route />
        </ThemeProvider>
      </StoreProvider>
    </React.Fragment>
  );
};

export default App;
