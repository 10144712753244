import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enLocaleResource from '../locales/en.json';
import zhLocaleResource from '../locales/zh.json';
import zhHKLocaleResource from '../locales/zh-HK.json';

export const SUPPORT_LANGUAGES = {
  zh: 'zh',
  en: 'en',
  'zh-HK': 'zh-HK',
} as const;
const TRANSLATION_LANG_KEY = Object.keys(SUPPORT_LANGUAGES);
export const DEFAULT_LANGUAGE = 'en';

i18n.use(initReactI18next).init({
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  keySeparator: '.',
  preload: TRANSLATION_LANG_KEY,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['b', 'br'],
  },
  resources: {
    en: {
      translation: enLocaleResource,
    },
    zh: {
      translation: zhLocaleResource,
    },
    'zh-HK': {
      translation: zhHKLocaleResource,
    },
  },
});

export default i18n;

export const getLangKeyFromProps = (langKey = DEFAULT_LANGUAGE): string => {
  return TRANSLATION_LANG_KEY.includes(langKey) ? langKey : DEFAULT_LANGUAGE;
};
