// Payment Link checkout page shares the same local storage with Airwallex Web App
// Make sure there's no unintentional conflict with Web App
export enum StorageKey {
  LANG_KEY = 'GET_PAID_CHECKOUT_LANG_KEY',
  IS_PAYMENT_LINK_AMOUNT_SET = 'IS_PAYMENT_LINK_AMOUNT_SET',
  INTENT_ID = 'INTENT_ID',
  CLIENT_SECRET = 'CLIENT_SECRET',
  PAYMENT_URL = 'PAYMENT_URL',
}

function dispatchLocalStorageEvent(key: StorageKey) {
  const event = new CustomEvent<StorageKey>('local-storage', { detail: key });
  window.dispatchEvent(event);
}

export function clientGetStorage(key: StorageKey): string | null {
  return localStorage.getItem(key);
}

export function clientSetStorage(key: StorageKey, value: string): void {
  localStorage.setItem(key, value);
  dispatchLocalStorageEvent(key);
}

export function clientRemoveStorage(key: StorageKey): void {
  localStorage.removeItem(key);
  dispatchLocalStorageEvent(key);
}
