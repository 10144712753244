/** @jsx jsx */
import { jsx, css } from '@emotion/core';

interface Props {
  animation?: 'pulse' | 'wave' | false;
  height?: number | string;
  variant?: 'text' | 'rect' | 'circle';
  width?: number | string;
  className?: string;
}

export const Index = ({ animation = 'wave', height, variant = 'rect', width, className = '' }: Props): JSX.Element => {
  const style = {
    text: css`
      margin-top: 0;
      margin-bottom: 0;
      height: ${height};
      width: ${width};
      transform-origin: 0 60%;
      transform: scale(1, 0.6);
      border-radius: 4px;
      display: block;
      background-color: rgba(0, 0, 0, 0.04);
    `,
    rect: css`
      height: ${height};
      width: ${width};
      display: block;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 3px;
    `,
    circle: css`
      height: ${height};
      width: ${width};
      display: block;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 50%;
    `,
    pulse: css`
      animation: pulse 1.5s ease-in-out 0.5s infinite;
      @keyframes pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        }
      }
    `,
    wave: css`
      position: relative;
      overflow: hidden;
      &::after {
        animation: wave 1.6s linear 0.5s infinite;
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.7), transparent);
        content: '';
        position: absolute;
        transform: translateX(-100%);
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
      }
      @keyframes wave {
        0% {
          transform: translateX(-100%);
        }
        60% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(100%);
        }
      }
    `,
  };

  const selectStyle = (animationType: keyof typeof style | false, variantType: keyof typeof style) => {
    if (animationType === false) {
      return style[variantType];
    }
    return [style[animationType], style[variantType]];
  };
  return <span css={selectStyle(animation, variant)} className={className} />;
};

export default Index;
