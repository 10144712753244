/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';

interface Props {
  customStyle?: SerializedStyles;
}

export const Index: FC<Props & HTMLAttributes<HTMLDivElement>> = ({ customStyle, ...rest }) => (
  <div
    css={[
      customStyle,
      css`
        ${customStyle};
        border: none;
        height: 1px;
        background: rgb(228, 234, 238);
      `,
    ]}
    {...rest}
  />
);

export default Index;
