import { StorageKey, clientGetStorage, clientSetStorage } from './localStorage';
import { DEFAULT_LANGUAGE, SUPPORT_LANGUAGES } from './i18n';

export const LANGUAGE_OPTIONS: {
  value: LangKey;
  label: string;
}[] = [
  {
    value: SUPPORT_LANGUAGES.en,
    label: 'English',
  },
  {
    value: SUPPORT_LANGUAGES.zh,
    label: '简体中文',
  },
  {
    value: SUPPORT_LANGUAGES['zh-HK'],
    label: '繁體中文',
  },
];

export type LangKey = keyof typeof SUPPORT_LANGUAGES;

export const getLanguage = (): LangKey => {
  const lsLanguage = clientGetStorage(StorageKey.LANG_KEY);
  if (lsLanguage && SUPPORT_LANGUAGES[lsLanguage as LangKey]) {
    return SUPPORT_LANGUAGES[lsLanguage as LangKey];
  }
  const browserLanguage = window.navigator.language.split('-')[0];
  const result = SUPPORT_LANGUAGES[browserLanguage as LangKey] || DEFAULT_LANGUAGE;
  clientSetStorage(StorageKey.LANG_KEY, result);
  return result;
};
