/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useLargeScreen } from '@/hooks/useMediaQuery';
import MobileSkeleton from './mobileSkeleton';
import Skeleton from '@/components/common/skeleton';
import Divider from '@/components/common/divider';
import { space } from '@airwallex/global';

const Index: React.FC = () => {
  const isLargeScreen = useLargeScreen();
  return (
    <div>
      {!isLargeScreen ? (
        <MobileSkeleton />
      ) : (
        <div
          data-testid="largeScreenSkeleton"
          css={css`
            width: 100%;
            max-width: 560px;
            margin: 223px auto 0;
          `}
        >
          <Skeleton width="100%" height="64px" />
          <Skeleton
            width="120px"
            height="21px"
            css={css`
              margin: ${space.xl} 0 ${space.xs};
            `}
          />
          <Skeleton width="100%" height="21px" />
          <Skeleton
            width="120px"
            height="21px"
            css={css`
              margin: ${space.s} 0 ${space.xs};
            `}
          />
          <Skeleton width="100%" height="21px" />
          <Skeleton
            width="120px"
            height="21px"
            css={css`
              margin: ${space.s} 0 ${space.xs};
            `}
          />
          <Skeleton width="100%" height="21px" />

          <Divider
            css={css`
              margin: ${space.xl} 0 ${space.m};
            `}
          />

          <Skeleton
            width="440px"
            height="21px"
            css={css`
              margin-bottom: ${space.xs};
            `}
          />
          <Skeleton width="240px" height="21px" />
        </div>
      )}
    </div>
  );
};

export default Index;
