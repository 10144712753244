/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useLocation, matchPath } from 'react-router-dom';
import MiddleSkeleton from './middleSkeleton';
import SplitSkeleton from './splitSkeleton';

const middleSkeleton = [
  '/pay/:region/link/payment/success',
  '/pay/:region/link/payment/error',
  '/pay/:region/invoice/:platform',
];
const Index: React.FC = () => {
  const { pathname } = useLocation();
  const isMatchMiddleSkeleton = middleSkeleton.some((path) => {
    return matchPath(pathname, {
      path,
      exact: true,
      strict: false,
    });
  });

  return <div>{isMatchMiddleSkeleton ? <MiddleSkeleton /> : <SplitSkeleton />}</div>;
};

export default Index;
