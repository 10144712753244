/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@/components/common/skeleton';
import Divider from '@/components/common/divider';
import { space } from '@airwallex/global';

const Index: React.FC = () => (
  <div
    data-testid="leftSkeleton"
    css={css`
      width: 100%;
      height: 100%;
      max-width: 440px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;
    `}
  >
    <div
      css={css`
        flex: 1;
      `}
    >
      <Skeleton width="120px" height="40px" />
      <Skeleton
        width="120px"
        height="23px"
        css={css`
          margin: ${space.l} 0 ${space.s};
        `}
      />
      <Skeleton width="100%" height="21px" />
      <Skeleton
        width="240px"
        height="21px"
        css={css`
          margin: ${space.xs} 0 ${space.l};
        `}
      />
      <Divider />
      <Skeleton
        width="240px"
        height="21px"
        css={css`
          margin: ${space.l} 0 13px;
        `}
      />
      <Skeleton width="120px" height="30px" />
    </div>
    <div
      css={css`
        flex: 0;
      `}
    >
      <Skeleton width="100%" height="21px" />
    </div>
  </div>
);

export default Index;
