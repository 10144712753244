import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pageView } from '@/utils/analytics/google';

const Analytics: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    pageView();
  }, [pathname]);

  return null;
};

export default Analytics;
