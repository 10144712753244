import { useState, useEffect } from 'react';
import { breakpoints } from '@airwallex/global';

const TABLET_BREAK_POINTS = {
  mobile: 500,
  desktop: 1200,
};

const useMediaQuery = (query: string) => {
  const [state, setState] = useState(() => window.matchMedia(query).matches);
  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    if (mql.addEventListener) {
      mql.addEventListener('change', onChange);
    } else {
      mql.addListener(onChange);
    }
    setState(mql.matches);

    return () => {
      mounted = false;
      if (mql.removeEventListener) {
        mql.removeEventListener('change', onChange);
      } else {
        mql.removeListener(onChange);
      }
    };
  }, [query]);

  return state;
};

export const useMobile = (): boolean => useMediaQuery(`(max-width: ${breakpoints.breakpointTokens.s}px)`);
export const useLargeScreen = (): boolean => useMediaQuery(`(min-width: ${breakpoints.breakpointTokens.l}px)`);
export const useMidWidthScreen = (): boolean =>
  useMediaQuery(
    `(min-width: ${breakpoints.breakpointTokens.s}px) and (max-width: ${breakpoints.breakpointTokens.l}px)`
  );
export const useTablet = (): boolean =>
  useMediaQuery(`(min-width: ${TABLET_BREAK_POINTS.mobile}px) and (max-width: ${TABLET_BREAK_POINTS.desktop}px)`);
