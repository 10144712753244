/** @jsx jsx */
import { jsx } from '@emotion/react';
import { createContext, FC, useState, useContext } from 'react';
import { ServerInvoiceRes } from '@/types/checkout';
import { IntentData, PayByLinkValues, ShopperData } from '@/components/paybylink/types';
import { CreateQuoteResponse } from '@/types/automaticCurrencyConversion';

export const PAY_BY_LINK_FORM_INITIAL_VALUES: PayByLinkValues = {
  amount: '',
  currency: '',
  name: '',
  email: '',
  sendEmail: true,
  phoneNumber: '',
  reference: '',
  shippingAddress: {
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    postCode: '',
  },
  comment: '',
};

type Store = {
  invoiceData: ServerInvoiceRes;
  setInvoiceData: (value: ServerInvoiceRes) => void;
  intentData: IntentData;
  setIntentData: (data: IntentData) => void;
  payByLinkFormData: PayByLinkValues;
  setPayByLinkFormData: (values: PayByLinkValues) => void;
  shopperData: ShopperData;
  setShopperData: (data: ShopperData) => void;
  automaticCurrencyConversionData: string[];
  setAutomaticCurrencyConversionData: (data: string[]) => void;
  quote: CreateQuoteResponse;
  setQuote: (data: CreateQuoteResponse) => void;
};

export const StoreContext = createContext<Store>({
  invoiceData: null,
  setInvoiceData: null,
  intentData: null,
  setIntentData: null,
  setPayByLinkFormData: null,
  payByLinkFormData: null,
  shopperData: null,
  setShopperData: null,
  automaticCurrencyConversionData: null,
  setAutomaticCurrencyConversionData: null,
  quote: null,
  setQuote: null,
});

export const StoreProvider: FC = ({ children }) => {
  const [invoiceData, setInvoiceData] = useState<ServerInvoiceRes | null>(null);
  const [intentData, setIntentData] = useState<IntentData | null>(null);
  const [payByLinkFormData, setPayByLinkFormData] = useState<PayByLinkValues | null>(PAY_BY_LINK_FORM_INITIAL_VALUES);
  const [shopperData, setShopperData] = useState<ShopperData | null>(null);
  const [automaticCurrencyConversionData, setAutomaticCurrencyConversionData] = useState<string[] | null>(null);
  const [quote, setQuote] = useState<CreateQuoteResponse | null>(null);
  return (
    <StoreContext.Provider
      value={{
        invoiceData,
        setInvoiceData,
        intentData,
        setIntentData,
        payByLinkFormData,
        setPayByLinkFormData,
        shopperData,
        setShopperData,
        automaticCurrencyConversionData,
        setAutomaticCurrencyConversionData,
        quote,
        setQuote,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = (): Store => useContext(StoreContext);
