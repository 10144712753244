import ReactGA from 'react-ga';
import { GoogleAnalytics } from './type';

const GAID = 'UA-76721226-1';

export enum Category {
  SendEmail = 'Send email',
  LanguageSelector = 'Language selector',
  ReceiptContact = 'Receipt contact',
  PrePaymentFailPage = 'Pre Payment fail page',
  ContinueToPayment = 'Continue to payment',
  PaymentFailPage = 'Payment fail page',
  CheckoutBack = 'Checkout Back',
  InvoiceNotAvailable = 'Xero invoice not available',
  InvoiceAlreadyPaid = 'Xero invoice already paid',
  InvoicePaymentError = 'Xero invoice payment error',
  InvoicePaymentOhterError = 'Xero invoice payment other error',
}

export enum Action {
  Click = 'click',
  Switch = 'switch',
  Send = 'send',
  Display = 'display',
}

export enum Label {
  SelectLanguage = 'Select language',
  MerchantEmail = 'Merchant email',
  MerchantTelephone = 'Merchant telephone',
  Reload = 'Reload',
  TryAgain = 'Try again',
}

export const initGA = (): void => {
  if (typeof window !== 'undefined' && !window.GA_INITIALIZED) {
    ReactGA.initialize(GAID, {
      titleCase: false,
    });
    window.GA_INITIALIZED = true;
  }
};

export const pageView: GoogleAnalytics['pageView'] = (location = window.location) => {
  if (window.GA_INITIALIZED) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
};

export const virtualPageView: GoogleAnalytics['virtualPageView'] = (path) => {
  if (window.GA_INITIALIZED) {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
  }
};

export const event: GoogleAnalytics['event'] = (category, action, label = '', rest = {}) => {
  if (window.GA_INITIALIZED) {
    ReactGA.event({ category, action, label, ...rest });
  }
};

export const outboundLink: GoogleAnalytics['outboundLink'] = (args, hitCallback, trackerNames?) => {
  if (window.GA_INITIALIZED) {
    ReactGA.outboundLink(args, hitCallback, trackerNames);
  }
};

export const set: GoogleAnalytics['set'] = (obj) => {
  if (window.GA_INITIALIZED) {
    ReactGA.set(obj);
  }
};

export const timing: GoogleAnalytics['timing'] = (category, variable, value, label): void => {
  if (window.GA_INITIALIZED) {
    ReactGA.timing({ category, variable, value, label });
  }
};
