import AirTracker, { Config, Environment } from '@airwallex/airtracker';
import versionFile from '../version.json';
import { getPageEnvByHost } from './environment';

const config: Config = {
  appName: 'pa_getpaid_checkout',
  appVersion: versionFile.version,
  env: getPageEnvByHost() as Environment,
  enableErrorMonitoring: true,
  enableWebVitals: true,
  assetSpeedMonitoringWhiteList: [/\.airwallex\.com\//],
};

export const airTracker = new AirTracker({ config });

export const TimerKey = {
  prepaymentGetLinkInfo: 'prepayment/GetLinkInfo',
  payByLinkOnPay: 'prepayment/onPay',
  sentryInitDuration: 'sentryInitDuration',
};

export const BusinessEvents = {
  enterPreviewPage: 'ENTER_PREVIEW_PAGE',
  enterPrePaymentPage: 'ENTER_PRE_PAYMENT_PAGE',
  clickContinuePaymentButton: 'CLICK_CONTINUE_PAYMENT_BUTTON',
  clickContinueSuccess: 'CLICK_CONTINUE_SUCCESS',
  enterPaymentPage: 'ENTER_PAYMENT_PAGE',
  enterSuccessPage: 'ENTER_SUCCESS_PAGE',
  enterErrorPage: 'ENTER_ERROR_PAGE',
  delayedStatusTransition: 'DELAYED_STATUS_TRANSITION',
  getPaymentStatusFailed: 'GET_PAYMENT_STATUS_FAILED',
  getPaymentStatusSuccess: 'GET_PAYMENT_STATUS_SUCCESS',
  getCurrencySwitcherSuccess: 'GET_CURRENCY_SWITCHER_SUCCESS',
  openPaymentlink: 'OPEN_PAYMENT_LINK',
};
