const apiEnvMapping = {
  www: '',
  demo: '-demo',
  staging: '-staging',
  dev: '-dev',
  pay: '',
  'pay-demo': '-demo',
  'pay-staging': '-staging',
  'pay-dev': '-dev',
};

const pageEnvMapping = {
  www: 'prod',
  demo: 'demo',
  staging: 'staging',
  dev: 'dev',
  pay: 'prod',
  'pay-demo': 'demo',
  'pay-staging': 'staging',
  'pay-dev': 'dev',
};

export const getApiEnvByHost = (): string => {
  const env = window.location.host.split('.')[0] as keyof typeof apiEnvMapping;
  if (env.includes('localhost')) {
    return apiEnvMapping.staging;
  }
  return typeof apiEnvMapping[env] !== 'undefined' ? apiEnvMapping[env] : '';
};

export const getPageEnvByHost = (): string => {
  const env = window.location.host.split('.')[0] as keyof typeof apiEnvMapping;
  return pageEnvMapping[env] || 'prod';
};

export const region = window.location.pathname.split('/')[2] || 'hk';

export const getAirwayUrl = (): string => `https://pci-api${getApiEnvByHost()}.airwallex.com/api/v1/pa`;

export const getCorpsiteUrl = (): string => {
  const airwallexEnv = getPageEnvByHost();
  if (['staging', 'demo', 'dev'].includes(airwallexEnv)) {
    // Local development
    return `https://${airwallexEnv}.airwallex.com`;
  }
  if (airwallexEnv === 'prod') {
    // Prod deployment
    return 'https://www.airwallex.com';
  }
  // dev deployment
  return 'https://staging.airwallex.com';
};
