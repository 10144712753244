import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loading from '@/components/skeleton';

import Analytics from '@/components/analytics';

const Checkout = lazy(() => import('./checkout'));
const CheckoutSuccess = lazy(() => import('./paymentSuccess'));
const InvoiceError = lazy(() => import('./error/invoiceError'));
const PaymentLinkError = lazy(() => import('./error/paymentLinkError'));
const PayByLinkPrePayment = lazy(() => import('./paybylink/prePayment'));
const PayByLinkPayment = lazy(() => import('./paybylink/payment'));
const PayByLinkPaymentSuccess = lazy(() => import('./paybylink/success'));

const Index: FC = () => {
  return (
    <BrowserRouter>
      <Analytics />
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* short payment link url */}
          <Route path="/:code" exact component={PayByLinkPrePayment} />
          {/* original payment link url */}
          <Route path="/pay/link/preview" exact component={PayByLinkPrePayment} />
          <Route path="/pay/:region/link/payment/success" exact component={PayByLinkPaymentSuccess} />
          <Route path="/pay/:region/link/payment/error" exact component={PaymentLinkError} />
          <Route path="/pay/:region/link/payment/:intentId" exact component={PayByLinkPayment} />
          <Route path="/pay/:region/link/:code" exact component={PayByLinkPrePayment} />
          <Route path="/pay/:region/invoice/success" exact component={CheckoutSuccess} />
          <Route path="/pay/:region/invoice/error" exact component={InvoiceError} />
          <Route path="/pay/:region/invoice/:platform" exact component={Checkout} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Index;
