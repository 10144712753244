// When you get a new color and want a name for it: https://www.color-name.com
export const hoverBorderGrey = '#87878E';
export const lightOrange = '#ffddc4';
export const yellow = '#FFC94C';
export const orange = '#FF8E3C';
export const awOrange = '#ff9800';

export const red = '#FF4F42';
export const lightRed = '#ff80ab';
export const lightPink = 'rgba(255, 79, 66, 0.3)';
export const lightUltraViolet = '#ea80fc';
export const backgroundUltraViolet = '#e6deff';
export const clickUltraViolet = 'rgba(97, 47, 255, 0.24)';
export const fireOpal = '#ef5350';
export const lavenderBlush = '#ffebee';

export const ultraViolet = '#612FFF';
export const lightViolet = '#f0efff';
export const softViolet = '#b3aeff';
export const hoverBgColorViolet = '#f3efff';
export const hoverBorderViolet = 'bfabff';
export const focusViolet = '#dfdeff';
export const veryLightBlue = '#7043ff';
export const yellow10 = '#fff8e0';
export const orange10 = '#ffede0';

export const white = '#fff';
export const inputBackground = '#FCFCFD';
export const inputPlaceholder = '#868E98';
export const bgGrey = '#F7F7F9';
export const boardBackground = '#f0f2f5';
export const softGrey = '#EBECF0';
export const borderLightGrey = '#e1e7f0';
export const flatGrey = '#d1d2d5';
export const greys60 = '#6C747F';
export const greys40 = '#B0B6BF';
export const greys80 = '#42474D';
export const greys90 = '#2F3237';
export const grey = '#A9A9AE';
export const darkGrey = '#54545E';
export const greyOnGreyText = '#87878E';
export const greyInput = '#1A1D21';
export const deepGrey = '#303030';
export const middleGrey = '#e8eaed';
export const feeGrey = '#6a6a74';
export const secondaryText = '#9d9da3';
export const lightGrey = '#757581';
export const shallowGrey = '#e4eaee';
export const textDarkGrey = '#a0a7b1';
export const SkeletonGrey = '#f6f7f8';
export const disableGrey = 'rgba(0, 0, 0, 0.26)';
export const ink = '#2A2A2A';
export const black = '#000';
export const subMenuBackground = '#212121';
export const subMenuBorder = 'rgba(0, 0, 0, 0.45)';
export const mobileBgColor = '#f3f4f8';
export const green10 = '#E0F7E7';
export const blackCoral = '#545B63';
export const royalOrange = '#ff8e3c';
export const ogreOdor = '#ff4f42';
export const borderError = '#FF776D';
export const bgError = '#FFEFEF';
export const backgroudGrey = '#FBFBFC';

export const Gradients = {
  headerBg: 'linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%)',
  yellow: 'linear-gradient(to right, rgba(254, 134, 56, 1) 1%,rgba(255, 197, 75, 1) 100%)',
  orange: 'linear-gradient(to right, rgba(252, 70, 66, 1) 1%,rgba(255, 139, 56, 1) 100%)',
  red: 'linear-gradient(to right, rgba(245, 25, 105, 1) 1%,rgba(254, 77, 70, 1) 100%)',
  black: 'linear-gradient(to right, #000 1%, #333 100%)',
  grey: 'linear-gradient(to right, #54545E 1%, #EBECF0 100%)',
  lightGrey: 'linear-gradient(to right, #eeeeee 1%, #fafafa 100%)',
  green: 'linear-gradient(to right, green 1%, #a5d6a7 100%)',
  blue: 'linear-gradient(to right, #34BACC 1%, #C2EAF0 100%)',
  notPink: 'linear-gradient(to right, rgba(202, 0, 179, 1) 1%,rgba(254, 17, 97, 1) 100%)',
  notPurple: 'linear-gradient(to right, rgba(99, 50, 255, 1) 1%,rgba(209, 0, 169, 1) 100%)',
};
