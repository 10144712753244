import './styles/global';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import App from './app';
import versionJSON from './version.json';
import { initGA } from '@/utils/analytics/google';
import { getPageEnvByHost } from '@/utils/environment';
import { airTracker, TimerKey } from './utils/airTracker';

if (window.location.host === 'www.airwallex.com') {
  initGA();
  const { version } = versionJSON;
  airTracker.timeStart(TimerKey.sentryInitDuration);
  Sentry.init({
    dsn: 'https://120bfcdc727948dcb8fe1723cfbae803@sentry.airwallex.com/326',
    denyUrls: [/localhost/i],
    environment: getPageEnvByHost(),
    integrations: [
      new RewriteFramesIntegration({
        iteratee: (frame) => {
          const result = {
            ...frame,
          };
          if (result.filename) {
            const url = new URL(window.location.href);
            result.filename = result.filename.replace(url.origin, '');
          }
          return result;
        },
      }),
      new BrowserTracing(),
    ],
    release: `getpaid-checkout@${version.substring(1)}`,
    dist: 'production',
    /**
     * Starting from the version 6.0.0, all SDKs that support sending sessions data will do so by default
     * We should opt-out of this behavior by setting autoSessionTracking: false option during SDK initialization
     * More infos can be found here: https://github.com/getsentry/sentry-javascript/blob/master/CHANGELOG.md#600
     */
    autoSessionTracking: false,
  });
  airTracker.timeEnd(TimerKey.sentryInitDuration);
}

ReactDOM.render(<App />, document.getElementById('root'));
