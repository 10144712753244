import { injectGlobal } from 'emotion';
import { colors, circularFontFamily, breakpoints } from '@airwallex/global';

const { breakpoint } = breakpoints;

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    background: ${colors.white};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    line-height: 1.5;
    margin: 0;
    padding: 0;
    background: ${colors.white};
    font-family: ${circularFontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;

    > li {
      display: block;
    }

    ${breakpoint.s} {
      background: ${colors.white};
    }
  }

  table {
    border-collapse: collapse;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
  }

  nav > div > a {
    line-height: 0;
    height: 29px;
    @media (min-width: 1347px) {
      height: 21px;
    }
  }
  .grecaptcha-badge {
    visibility: hidden;
  }

`;
